import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import font from "../../fonts/fonts.module.scss"
import logo from "../../images/global/logo-branco.svg"
import logoLink from "../../images/global/logo-seta-hollow.svg"
import setaO from "../../images/global/logo-seta-full.svg"

const Page = ({data}) => {
  const background = data.background.childImageSharp.fluid;
  
  return(
    <StyledPage fluid={background}>
      <Link to="/" className="logo">
        <img src={logo} alt="Logo"/>
      </Link>
      <div className="content">
        <p className={font.aGM + " subtitle"}>{data.content.subtitle}</p>
        <p className={font.mER}>{data.content.title}</p>
        <p className={font.aGR + " text"}>{data.content.text[0]} {data.content.text[1]}</p>
        <Link to={data.content.link} className={font.aGM + " mais-link"}>
          <img src={logoLink} alt="seta"/>
          <img src={setaO} alt="seta" className="hoverArrow"/>
          {data.content.linkTxt}
        </Link>
      </div>

      <div className="info">
        {/* <Link to="/404" className={font.aGR + " small gridFull"}>{data.info.contact.newsletter}</Link> */}
        <div className="address">
          {data.info.address.map((data, i)=>(
            <p className={font.aGR + " small"} key={"line" + i}>{data}</p>
          ))}
        </div>
        <div className="contact">
          <a href={"mailto: " + data.info.contact.email} className={font.aGR + " small"}>{data.info.contact.email}</a>
          <a href={"tel: " + data.info.contact.phone} className={font.aGR + " small small-gridfull"}>{data.info.contact.phone}</a>
          <a className={font.aGR + " small"}>«Chamada para a rede fixa nacional»</a>
        </div>
      </div>
    </StyledPage>
  )
}

export default Page

const StyledPage = styled(BackgroundImage)`
  position: relative;
  flex: 1;
  height: 100%;
  opacity: 1 !important;
  .logo{
    position: absolute;
    top: 3vh;
    left: 10%;
    width: clamp(150px, 12vw, 350px);
    img{width: 100%;}
  }
  .content{
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    background-color: #ffffff;
    width: 60%;
    min-width: 500px;
    max-width: 90%;
    padding: 5vw;
    @media only screen and (max-width: 1300px){
      width: 80%;
      min-width: unset;
      max-width: unset;
    }
    .subtitle{
      color: #fa4616;
      margin-bottom: 0.5em;
    }
    .text{margin: 1.5em 0;}
    .mais-link{
      display: flex;
      position: relative;
      transition: all 200ms ease-out;
      text-transform: uppercase;
      align-items: center;
      .hoverArrow{
        position: absolute;
        left: 0;
        opacity: 0;
        transition: all 200ms ease-out;
      }
      img{height: 1em; margin-right: 1em;}
      :hover{
        letter-spacing: 0.1em;
        .hoverArrow{
          opacity: 1;
        }
      }
    }
  }
  .info{
    position: absolute;
    left: 10%;
    bottom: 3vh;
    color: #ffffff;
    display: grid;
    grid-template-columns: 1fr 1fr 0.7fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    a{text-decoration: underline; line-height: 1.5em;}
    .gridFull{
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
`