import React from "react"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick";
import font from "../../fonts/fonts.module.scss"
import menuClose from "../../images/global/menu-close.svg"
import logoLink from "../../images/global/logo-seta-full.svg"

const Page = ({data, close}) => {
  const image = data.bg.childImageSharp.fluid;
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  
  return(
    <StyledPage fluid={image}>
      <button className="menu-close" onClick={()=>{close(false)}}>
        <img src={menuClose} alt="Menu"/>
      </button>
      <div className="links">
        {data.links.map((data, i)=>(
          i!==0 &&
          <button onClick={()=>{close(false)}}>
            <Link to={data.link} key={"Link nº" + i+1} activeClassName="link-active"><h1 className={font.aGB}>{data.linkTxt}</h1></Link>
          </button>
        ))}
      </div>
      <div className="highlights">
        <div className="highlights-title">
          <p className={font.aGR + " subtitle small"}>{data.highlights.subtitle}</p>
          <h3 className={font.mER + " title"}>{data.highlights.title}</h3>
        </div>
        <Slider {...settings}>
          {data.highlights.list.map((data, i)=>(
            <div className="slide" key={"clinicaSlide" + i}>
              <GatsbyImage image={data.img.childImageSharp.gatsbyImageData} alt={data.title[0]}/>
              <Link to={data.link !== "/404" && data.link} className="slide-info">
                <img src={logoLink} alt="seta"/>
                <div className="slide-info-text">
                  <p className={font.aGR}>{data.title[0]}<br/>{data.title[1]}</p>
                  {/* <p className={font.mER + " small spacing"}>{data.subtitle}</p> */}
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </StyledPage>
  )
}

export default Page

const StyledPage = styled(BackgroundImage)`
  position: relative;
  flex: 1;
  height: 100%;
  background-color: #000000;
  overflow: hidden;
  .menu-close{
    position: absolute;
    right: 10%;
    top: 3vh;
    width: clamp(15px, 1.6vw, 45px);
    img{
      position: absolute;
      left: 50%;
      width: 70%;
      transform: translateX(-50%);
    }
  }
  .info{
    position: absolute;
    top: 3vh;
    left: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2vw;
    color: #ffffff;
    @media only screen and (max-width: 1000px){
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 6vw;
      grid-row-gap: 1vw;
      .small-gridfull{
        grid-column-start: 1;
        grid-column-end: 3;
        text-align: right;
      }
    }
  }
  .links{
    position: absolute;
    top: 20%;
    left: 10%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    a{
      transition: color 150ms ease-out;
      :hover{
        color: #fa4616;
      }
    }
    .link-active{
      color: #fa4616;
    }
  }
  .highlights{
    position: absolute;
    bottom: 3vh;
    left: 50%;
    transform: translateX(-50%);
    width: 150%;
    @media only screen and (max-width: 1199px){
      width: 200%;
    }
    @media only screen and (max-width: 999px){
      width: 220%;
    }
    .highlights-title{
      margin: 0 36.5% 1em;
      width: fit-content;
      .title{color: #fa4616;}
      .subtitle{color: #ffffff;}
    }
    .slide{
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      outline: none;
      padding: 0 5%;
      opacity: 0.8;
      transition: opacity 200ms ease-out;
      :hover{opacity: 1;}
      .slide-info{
        position: relative;
        display: flex;
        align-items: center;
        opacity: 0;
        transition: opacity 0.4s ease-out;
        margin-top: 1em;
        img{
          height: 1.5em;
          margin-right: 1em;
        }
        .slide-info-text{
          color: #ffffff;
          .spacing{
            color: #25282a;
            letter-spacing: 0.1em;
          }
        }
      }
    }
    .slick-current{
      .slide{
        opacity: 1;
        .slide-info{
          opacity: 1;
        }
      }
    }
    .slick-track{
      display: flex;
    }
    .slick-list{
      overflow: hidden;
    }
  }
`