import React, { useState } from "react"
import styled from "styled-components"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"
import MenuWindow from "./menu-window"
import MenuWindowMobile from "./menu-windowMobile"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import logo from "../../images/global/logo.svg"
import menu from "../../images/global/menu.svg"
import menuO from "../../images/global/menu-laranja.svg"

import useLockBodyScroll from "../../custom/use-lock-body-scroll"

const Menu = ({data}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  useLockBodyScroll(menuOpen);
  
  return(
    <StyledMenu>
      <Link to="/">
        <img src={logo} alt="Logo" className="leftPage-logo"/>
      </Link>
      <button className="menu-btn" onClick={()=>{setMenuOpen(!menuOpen)}}>
        <img src={menuO} alt="Menu"/>
      </button>
      <CSSTransition in={menuOpen} timeout={350} classNames={"switchModal"} unmountOnExit>
        {useBreakpoint().mobile ? (
        <>
          <MenuWindowMobile data={data} close={setMenuOpen}/>            
        </>
      ) : (
        <>
          <MenuWindow data={data} close={setMenuOpen}/>      
        </>
      )}
      </CSSTransition>
    </StyledMenu>
  )
}

export default Menu

const StyledMenu = styled.div`
  z-index: 5;
  position: relative;
  .leftPage-logo{
    position: absolute;
    top: 5vh;
    left: 5%;
    transform: translateY(-50%);
    width: clamp(150px, 12vw, 350px);
  }
  .menu-btn{
    position: absolute;
    right: calc(5% - 20px);
    top: 5vh;
    transform: translateY(-50%);
    width: clamp(20px, 1.6vw, 45px);  
    padding: 20px;
    img{
      width: 100%;
      transition: all 200ms ease-out;
      opacity: 1;
    }
    .menuHover{
      position: absolute;
      right: 0;
      opacity: 0;
    }
  }
  @media only screen and (max-width: 768px){
    .leftPage-logo{
      left: 7%;
    }
    .menu-btn{
      right: calc(7% - 20px);
      width: clamp(15px, 2.5vw, 45px);
    }
  }

  /* react-transition-group */
  .switchModal-enter{
    position: absolute;
    right: -100% !important;
  }
  .switchModal-enter-active{
    position: absolute;
    right: 0 !important;
    transition: all 350ms ease-out;
  }
  .switchModal-exit{
    position: absolute;
    right: 0 !important;
  }
  .switchModal-exit-active{
    position: absolute;
    right: -100% !important;
    transition: all 350ms ease-out;
  }
`