import React, { useState } from "react"
import styled from "styled-components"
import Div100vh from 'react-div-100vh'
import BackgroundImage from 'gatsby-background-image'
import { GatsbyImage } from "gatsby-plugin-image"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"
import font from "../../fonts/fonts.module.scss"
import logo from "../../images/global/logo-branco.svg"
import menuClose from "../../images/global/menu-close.svg"
import logoLink from "../../images/global/logo-seta-hollow.svg"

const Menu = ({data, close}) => {
  const background = data.leftPage.background.childImageSharp.fluid;
  const [about, setAbout] = useState(false);
  const [reverse, setReverse] = useState(false);
  
  return(
    <StyledMenu>
      <StyledPage fluid={background}>
      <div className="filter"/>
      <Link to="/" className="logo">
        <img src={logo} alt="Logo"/>
      </Link>
      <button className="menu-close" onClick={()=>{close(false)}}>
        <img src={menuClose} alt="Menu"/>
      </button>

      <CSSTransition in={!about} timeout={350} classNames={reverse ? "switchModalReverse" : "switchModal"} unmountOnExit>
        <div className="links">
          {data.rightPage.links.map((data, i)=>(
            i===0 ? 
            // <button onClick={()=>{setAbout(true); setReverse(false);}} key={"Link nº" + i+1}><h1 className={font.aGB}>{data.linkTxt}</h1></button>
            <button onClick={()=>{close(false)}}>
              <Link to={data.link} key={"Link nº" + i+1} activeClassName="link-active"><h1 className={font.aGB}>{data.linkTxt}</h1></Link>
            </button>
            :
            <button onClick={()=>{close(false)}}>
              <Link to={data.link} key={"Link nº" + i+1} activeClassName="link-active"><h1 className={font.aGB}>{data.linkTxt}</h1></Link>
            </button>
          ))}
        </div>
      </CSSTransition>
      <CSSTransition in={about} timeout={350} classNames={reverse ? "switchModalReverse" : "switchModal"} unmountOnExit>
        <div className="content">
          <button className={font.mER + " about-back"} onClick={()=>{setAbout(false); setReverse(true);}}>Voltar Atrás</button>
          <p className={font.aGM + " subtitle"}>{data.leftPage.content.subtitle}</p>
          <p className={font.mER  + " title"}>{data.leftPage.content.title}</p>
          <p className={font.aGR + " text"}>{data.leftPage.content.text[0]}</p>
          <Link to={data.leftPage.content.link} className={font.aGM + " mais-link"} onClick={()=>{close(false)}}>
            <img src={logoLink} alt="seta"/>
            {data.leftPage.content.linkTxt}
          </Link>
        </div>
      </CSSTransition>

      {/* <div className="info">
        <Link to="/404" className={font.aGR + " small"}>{data.leftPage.info.contact.newsletter}</Link>
        
        <div className="address">
          <p className={font.aGR}>{data.leftPage.info.address[0]}</p>
          <p className={font.aGR}>{data.leftPage.info.address[1]}</p>
          <p className={font.aGR}>{data.leftPage.info.address[2]}</p>
        </div>

        <div className="contact">
          <a href={"mailto: " + data.leftPage.info.contact.email} className={font.aGR}>{data.leftPage.info.contact.email}</a>
          <a href={"tel: " + data.leftPage.info.contact.phone} className={font.aGR}>{data.leftPage.info.contact.phone}</a>
          <Link to="https://www.livroreclamacoes.pt/" className="livro-recla">
            <GatsbyImage image={data.leftPage.livro.childImageSharp.gatsbyImageData} alt={"Livro de Reclamações"} className="livro-img"/>
          </Link>
        </div>
      </div> */}

      </StyledPage>
    </StyledMenu>
  )
}

export default Menu

const StyledMenu = styled(Div100vh)`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
`

const StyledPage = styled(BackgroundImage)`
  position: relative;
  width: 100%;
  height: 100%;
  .filter{
    height: 100%;
    width: 100%;
    background-color: #000000;
    opacity: 0.9;
  }
  .logo{
    position: absolute;
    top: 5vh;
    left: 7%;
    transform: translateY(-50%);
    width: clamp(150px, 20%, 350px);
    img{width: 100%;}
  }
  .menu-close{
    position: absolute;
    right: 7%;
    top: 5vh;
    transform: translateY(-50%);
    width: clamp(15px, 2.5vw, 45px);
    img{
      width: 100%;
    }
  }

  .links{
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    color: #ffffff;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    .link-active{
      color: #fa4616;
    }
  }

  .content{
    position: absolute;
    top: 45%;
    left: 5%;
    transform: translateY(-50%);
    background-color: #ffffff;
    width: 90%;
    padding: clamp(25px, 8vw, 30px) 8vw;
    .about-back{
      position: absolute;
      top: -3em;
      left: 0;
      color: #ffffff;
      letter-spacing: 0.12em;
      font-size: calc(13px + 15 * (100vw - 320px) / 448);
    }
    .subtitle{
      color: #fa4616;
      margin-bottom: 0.5em;
      font-size: calc(13px + 15 * (100vw - 320px) / 448);
    }
    .title{
      font-size: calc(13px + 15 * (100vw - 320px) / 448);
    }
    .text{
      margin: 1.5em 0;
      font-size: calc(13px + 15 * (100vw - 320px) / 448);
    }
    .mais-link{
      display: flex;
      font-size: calc(13px + 15 * (100vw - 320px) / 448);
      img{height: 1em; margin-right: 1em;}
    }
  }

  .info{
    position: absolute;
    bottom: 3vh;
    left: 5%;
    width: 90%;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 2em;
    color: #ffffff;
    p, a{font-size: calc(13px + 15 * (100vw - 320px) / 448);}
    a{text-decoration: underline; padding: 0.1em 0;}
    .gridFull{
      grid-column-start: 1;
      grid-column-end: 3;
      height: 1em;
    }
    .inv{text-align: right; margin-top: 1em;}
  }
  .contact{
    position: relative;
    .livro-recla{
      position: absolute;
      right: 0;
      top: -35%;
      width: 35%;
      .livro-img{
        width: 100%;
      }
    }
  }


  /* react-transition-group */
  .switchModal-enter{
    left: -100% !important;
  }
  .switchModal-enter-active{
    left: 5% !important;
    transition: all 350ms ease-out;
  }
  .switchModal-exit{
    left: 5% !important;
  }
  .switchModal-exit-active{
    left: 100% !important;
    transition: all 350ms ease-out;
  }

  .switchModalReverse-enter{
    left: 100% !important;
  }
  .switchModalReverse-enter-active{
    left: 5% !important;
    transition: all 350ms ease-out;
  }
  .switchModalReverse-exit{
    left: 5% !important;
  }
  .switchModalReverse-exit-active{
    left: -100% !important;
    transition: all 350ms ease-out;
  }
`