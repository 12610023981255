import React from "react"
import styled from "styled-components"
import Div100vh from 'react-div-100vh'
import { Link } from "gatsby"
import PageR from "./menu-page-right"
import PageL from "./menu-page-left"
import logoLaranja from "../../images/global/logo-laranja.svg"
import face from "../../images/global/socials/face.svg"
import insta from "../../images/global/socials/insta.svg"
import linked from "../../images/global/socials/linked.svg"
import balls from "../../images/global/menu-balls.svg"

const Menu = ({data, close}) => {
  
  return(
    <StyledMenu>
      <PageL data={data.leftPage}/>

      <div className="index">
        <div className="socials">
          {/* <a href="https://invisual.pt" className="social-link">
            <img src={face} alt="Facebook"/>
          </a>
          <a href="https://invisual.pt" className="social-link">
            <img src={insta} alt="Instagram"/>
          </a>
          <a href="https://invisual.pt" className="social-link">
            <img src={linked} alt="LinkedIn"/>
          </a> */}
          {/* <img src={balls} alt="Decoration"/> */}
        </div>
        <Link to="/" className="index-logo">
          <img src={logoLaranja} alt="Logo" />
        </Link>
      </div>

      <PageR data={data.rightPage} close={close}/>
    </StyledMenu>
  )
}

export default Menu

const StyledMenu = styled(Div100vh)`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 6;
  .index{
    position: relative;
    width: 2%;
    min-width: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    .socials{
      display: flex;
      flex-direction: column;
      align-items: center;
      .social-link{
        margin: 1em 0;
        img{width: 1em;}
      }
    }
    .index-info{
      color: #fa461d;
      margin: 1em 0;
    }
    .index-logo{
      position: absolute;
      bottom: 25px;
      width: 45%;
      img{width: 100%;}
    }
  }
`